import React from 'react';

let reviewArray = [
    {
        review:"التطبيق مصمم ليتناسب مع كل شخص، كما أن التمارين ليست صعبة بالنسبة للمبتدئين وتزداد صعوبة مع تقدم مستوى اللياقة، النظام الغذائي سهل الإلتزام به، وليس مكلف من الناحية المادية",
        name:"أمل الحدادي",
        country:"United Arab Emirates",
        store:"Play Store"
    },
    {
        review:"أفضل تطبيق عربي، وحتى على مستوى عالمي، سعر اشتراك بسيط نسبيًا، وتمارين فعالة جدًا",
        name:"وليد خضر",
        country:"Saudi Arabia",
        store:"App Store"
    },
    {
        review:"مكنتش بستمر على أي نظام وكنت بزهق على طول بس مع الكوتش محستش بأي ملل خالص، التمارين متنوعة وبيفكرني كل شوية أشرب مية، يستاهل الصراحة أكثر من ٥ نجوم.",
        name:"أحمد عبد الله",
        country:"Egypt",
        store:"Play Store"
    },
    {
        review:"الكوتش أكتر برنامج مفهوم ومنظم. غيّر حياتي حرفياً، البرامج الأخرى يا معقدة يا غير واضحة",
        name:"هوى الجهيمي.",
        country:"Saudi Arabia",
        store:"App Store"
    },
    {
        review:"أكثر شيء حبيته في الكوتش هو وضوح سهولة الاستخدام، النظام الغذائي المتنوع، وسرعة الرد",
        name:"أحمد سمير.",
        country:"Egypt",
        store:"App Store"
    },
]

export default function Review({ title, html }) {
    var index = Math.floor(Math.random() * 5);
    index = index >= reviewArray.length ? 0 : index;
    let review = reviewArray[index];
    return (
        <div className="feedback text-center">
            <div className="rating">
                <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    className="star-active"
                    viewBox="0 0 512 512"
                    styles="enable-background:new 0 0 512 512;"
                >
                    <g>
                        <g>
                            <polygon
                                points="512,197.816 325.961,185.585 255.898,9.569 185.835,185.585 0,197.816 142.534,318.842 95.762,502.431 
                                                255.898,401.21 416.035,502.431 369.263,318.842"
                            />
                        </g>
                    </g>
                </svg>
                <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    className="star-active"
                    viewBox="0 0 512 512"
                    styles="enable-background:new 0 0 512 512;"
                >
                    <g>
                        <g>
                            <polygon
                                points="512,197.816 325.961,185.585 255.898,9.569 185.835,185.585 0,197.816 142.534,318.842 95.762,502.431 
                                            255.898,401.21 416.035,502.431 369.263,318.842"
                            />
                        </g>
                    </g>
                </svg>
                <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px" y="0px"
                    className="star-active"
                    viewBox="0 0 512 512"
                    styles="enable-background:new 0 0 512 512;"
                >
                    <g>
                        <g>
                            <polygon
                                points="512,197.816 325.961,185.585 255.898,9.569 185.835,185.585 0,197.816 142.534,318.842 95.762,502.431 
                                    255.898,401.21 416.035,502.431 369.263,318.842"
                            />
                        </g>
                    </g>
                </svg>
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className="star-active"
                    viewBox="0 0 512 512"
                    styles="enable-background:new 0 0 512 512;" >
                    <g>
                        <g>
                            <polygon
                                points="512,197.816 325.961,185.585 255.898,9.569 185.835,185.585 0,197.816 142.534,318.842 95.762,502.431 
                                        255.898,401.21 416.035,502.431 369.263,318.842"
                            />
                        </g>
                    </g>
                </svg>
                <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    className="star-active"
                    viewBox="0 0 512 512"
                    styles="enable-background:new 0 0 512 512;"
                >
                    <g>
                        <g>
                            <polygon
                                points="512,197.816 325.961,185.585 255.898,9.569 185.835,185.585 0,197.816 142.534,318.842 95.762,502.431 
                                255.898,401.21 416.035,502.431 369.263,318.842"
                            />
                        </g>
                    </g>
                </svg>
            </div>
            <p>{review.review}</p>
            <div className="user" id="user-testimonial">
                <div className="user-data">
                    <p className="user-name" id="user-name">{review.name}</p>
                    <p className="source" id="source">{review.store}</p>
                    <p className="source" id="source">{review.country}</p>
                </div>
            </div>
        </div>
    );
  }